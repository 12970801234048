import { CheckBalanceResult } from '../../../../modules/common/order-entry/models/check-balance.response';
import { TipoDocumento } from '../../../../modules/common/order-entry/models/foto-documenti';
import { MotivoRevoca } from '../../../../modules/domiciliation/init-domiciliation/init-domiciliation.component';
import { TransferModelResponse } from '../../../../modules/transfer/transfer-model/models/transfer-model-response';
import {
    GasAdministrativeTechnicalData,
    TitolaritaImmobile,
    WinBackType,
} from '../../../../store/models/order-entry-state';
import { AptDeliveryChannelQuote } from '../../../enums/apttus/apt-delivery-channel';
import { AptContactRole } from '../../../enums/apttus/apt-mb-representative';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { AptPaymentType } from '../../../enums/apttus/apt-payment-type';
import { D365AccountMigrated } from '../../../enums/d365/d365-account-migrated';
import { D365ConfirmationType } from '../../../enums/d365/d365-confirmation-type';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import { D365SignatureType } from '../../../enums/d365/d365-signature-type';
import { NumberOfEmployees, Revenue } from '../../../enums/shared/market-profiling.enum';
import { SiNoType } from '../../app/si-no-type';
import { Gender } from '../../user/contact';

export enum TaxVatScipafi {
    Ok = 'OK',
    Ko = 'KO',
    Retry = 'RETRY',
}
export interface Privacy {
    privacy1: boolean;
    privacy2: boolean;
    privacy3: boolean;
}

export interface CreditCheck {
    blacklist: string;
    blacklist2: string;
    newBlacklist: string;
    whitelist: string;
    unsolvedNds: string;
    avgInvoiceAmount: string;
    residualNds: string;
    unsolvedTaxCodeNds: string;
    unsolvedTaxCodeNdsResult: string;
    paymentScore: string;
    ceaseReasonCode: string;
    unsolvedIbanNds: string;
    cribis: string;
    checkDate: Date;
    taxVatScipafi: TaxVatScipafi;
    taxVatSdi: string;
    canProceed: boolean;
    checkBalanceExceeded: CheckBalanceResult;
}

export interface KeyValue {
    key: string;
    value: string;
}

export interface Products {
    lineItemId: string;
    productId: string;
    name: string;
    family: string;
    realEstateOwnership: TitolaritaImmobile;
    isWinBack: boolean;
    sourceCustomerAssetId: string;
    pdf?: string; // SOLO commodity
    attributeValues: KeyValue[];
    shippingAddress: CTQAddress;
    supplyAddress: CTQAddress;
    appointment: CommodityAppointment;
    prices: ProductPrices;
    selfReading: number;
    gasTechnicalDetails: Partial<GasAdministrativeTechnicalData>;
    immediateEffect: boolean;
    effectiveDate: Date;
    bookableResource: string;
    ese: string;
    bookingId: string;
    siteInspection: string;
    supplyAssetId: string;

    //   ***PER SWITCH-IN recuperare mappa chiavi PAV***
    //   powerConsumption: number;
    //   powerDeclared: number;
    //   powerRequired: string; // potenza impegnata MB
    //   powerVoltage: string; // livello tensione 220V; 380V; ...
    //   powerAvailable: string; // potenza disponibile MB
    //   gasConsumption: number;
    //   gasTypeOfUse: string; // 'COTTURA|ACQUA CALDA|RISCALDAMENTO|ALTRI USI'; concatenare i vari tipi d'uso
    //   pod: string;
    //   pdr: string;
}

export interface ProductPrices {
    priceSDR: string;
    priceOPS: string;
}

export interface CartToQuoteAppointment {
    //In presenza se firstName, lastName e phone compilati
    contactFirstName: string;
    contactLastName: string;
    contactPrefix: string;
    contactPhone: string;
    timeSlot: string;
    interphoneAvailable: boolean;
    interphoneNotes: string;
    presence: boolean;
}

export interface CommodityAppointment {
    isAvailable: boolean;
    location: string;
    notes: string;
}

export interface ElectronicInvoicing {
    recipientCode: string;
    pec: string;
}

export interface Account {
    customerCode: string;
    segment: D365CustomerSegment;
    firstname: string;
    lastname: string;
    companyName: string;
    mastershipCode: D365AccountMigrated;

    taxCode: string;
    vatCode: string;
    emailAddress: string;
    telephone1prefix: string;
    telephone1: string;
    telephone2prefix: string;
    telephone2: string;

    companyAddress: CTQAddress;
    homeAddress: CTQAddress;
    domicileAddress: CTQAddress;
    communicationAddress: CTQAddress;

    companyLegalForm: string;
    companyAtecoCode: string;
    companyAtecoActivity: string;
    companyAtecoSector: string;
    companyNumberOfEmployees: NumberOfEmployees;
    companyRevenue: Revenue;

    oldTelephone1?: string;
    oldEmailAddress?: string;
    favoriteCommunicationChannel: string;

    socialbonus: boolean;
    saemapre: boolean;
    over75: boolean;
    disability: boolean;
}

export interface CTQContact {
    type: AptContactRole;
    firstname: string;
    lastname: string;
    taxcode: string;
    gender?: Gender;
    birthDate?: string; // formato YYYY-MM-DD
    birthProvince?: string;
    birthCountry?: string;
    birthPlace?: string;
    isCustomer?: boolean; // aggiunta per bug #218876
}

export interface CTQAddress {
    toponym: string;
    street: string;
    civic: string;
    municipality: string;
    region?: string;
    shortProvince: string;
    province: string;
    cap: string;
    country: string;
    fullAddress: string;
    istatCodeProv: string;
    istatCodeMunicipality: string;

    civicEgonCode: number;
    streetEgonCode: number;
    certified: boolean;
    isDwellingTypePropertyHome?: boolean;
    isDwellingTypeUsualHome?: boolean;
}

export interface AttachmentInfo {
    id: string; // identificativo utile per recuperare documento (docstore)
    type: string;
    fileName: string;
    fileSize: string;
}

export interface IdentityDocumentInfo {
    documentType: TipoDocumento;
    documentNumber: string;
    issuedBy: string;
    issueDate: Date;
}

export interface ExecutionType {
    commodityQuickPassage: boolean;
    commodityEstimatedDate: Date;
    maintenanceQuickPassage: boolean;
    maintenanceEstimatedDate: Date;
}

export interface PaymentTool {
    id: string;
    oldId: string;
    instrumentType: AptPaymentInstrument;
    membership: string;
    billingPreferenceCode: string;
    iban?: string;
    holder?: HolderPaymentTool;
    revocationReason?: MotivoRevoca | undefined;
    caseID?: string;
    sepaSubscriber?: {
        taxcode: string;
        firstname: string;
        lastname: string;
    };
}

export interface HolderPaymentTool {
    // intestatario conto
    companyName: string; // in caso di MB
    companyVatcode: string; // in caso di MB
    taxcode: string;
    firstname: string;
    lastname: string;
}

export interface BillingAccount {
    type: AptPaymentType; // Frequenza pagamento 'Soluzione Unica' | 'Rateizzo'
    billingAddress: CTQAddress;
    paymentTool: PaymentTool;
    deliveryChannel: AptDeliveryChannelQuote;
    provider?: string;
}

export interface ChannelAndAgency {
    userId: string;
    userProfile: string;
    salesAgent: string;
    agencyCode: string;
    userCode: string;
    salesChannel: string;
    salesChannelName: string;
    employeeLastname: string;
    numberSignRUIagency: string;
    dateSignRUIagency: string;
    companyName: string;
    addressAgency: string;
    systemOrigin: string;
    salesAgentBlacklist: boolean;
    instoreSale: SiNoType;
    intermediaryFirstname: string;
    seatName: string;
    mailSeat: string;
    pecSeat: string;
    intermediaryLastname: string;
    addressSeat: string;
    pecAgency: string;
    phoneSeat: string;
    dateSignRUIintermediary: string;
    numberSignintermediary: string;
    operationalSeats: string;
    coCode: string;
}

export interface LeadAndCampaign {
    leadId: string;
    campaignId: string;
    campaignName: string;
    campaignCode: string;
    campaignTypeCode: string;
}

export interface InvalidCf {
    previousFiscalcode: string;
    customerCode: string;
}

export interface Signature {
    mode: D365SignatureType;
    date: Date;
    checkcallBlocking: boolean;
    confirmMode: D365ConfirmationType;
    mp3Sent: boolean;
    mp3Date: Date;
    isDelayInsertion: boolean;
}

export interface CartToQuoteRequest {
    cartId: string;
    quoteStatus: string;
    quoteSubStatus: string;
    combinedSaleQuoteId: string;

    account: Account;
    contact: CTQContact;
    identityDocument: IdentityDocumentInfo;
    creditCheckDetails: CreditCheck;
    privacy: Privacy;
    isWinBack: boolean;
    winBackType: WinBackType;
    salesProcess: string;
    contractCode: string;
    billingAccount: BillingAccount;
    electronicInvoicing: ElectronicInvoicing;
    products: Products[];
    realEstateOwnership: TitolaritaImmobile;
    channelAndAgency: ChannelAndAgency;

    invalidCf: InvalidCf;
    leadAndCampaign: LeadAndCampaign;
    signature: Signature;
    executionType: ExecutionType;
    attachments: AttachmentInfo[];
    isMortisCausa: boolean;

    partNumber: string;
    appointment: CartToQuoteAppointment;
    attributes?: KeyValue[];
    incidentId: string;
    skipCosts: boolean;

    agreementCode?: string; // Codice della convenzione intesa come Incentive (è un codice diverso dalla convenzione che viene messa nello state)

    sendCommunications?: boolean; // Invio comunicazioni amministrative
    sourceCustomerMastershipCode?: D365AccountMigrated;

    dataOwnershipChangeCode?: string;
    blockDataEditingOnDoiCheckDoiResend?: boolean;
    transferModel?: TransferModelResponse;
    idChiamata?: string;
    telefonoLeadPrimario?: string;
    telefonoLeadSecondario?: string;
}
