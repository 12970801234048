import { Regex } from '../../../config/regex';
import { LookupFilter } from '../../../models/d365/lookup-filter';
import { FetchXmlQuery } from './fetch-xml-manager';

export const INCIDENTS_BY_CUSTOMER_CODE: FetchXmlQuery = {
    entity: 'incident',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="incident">
            <attribute name="title" />
            <attribute name="ticketnumber" />
            <attribute name="customerid" />
            <attribute name="createdon" />
            <attribute name="incidentid" />
            <attribute name="caseorigincode" />
            <attribute name="statecode" />
            <attribute name="statuscode" />
            <attribute name="egl_caserequesttype" />
            <attribute name="egl_casecharacteristic" />
            <attribute name="egl_caselevel1id" />
            <attribute name="egl_caselevel2id" />
            <attribute name="egl_caselevel3id" />
            <order attribute="title" descending="false" />
            <link-entity name="account" from="accountid" to="customerid" link-type="inner" alias="ad">
                <filter type="and">
                    <condition attribute="egl_customercode" operator="eq" value="##customerCode##" />
                </filter>
            </link-entity>
        </entity>
    </fetch>`,
};

export const CASE_DATA_BY_CASE_ID: FetchXmlQuery = {
    entity: 'incident',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false" top="1">
        <entity name="incident">
            <attribute name="title" />
            <attribute name="ticketnumber" />
            <attribute name="egl_casecharacteristic" />
            <attribute name="incidentid" />
            <attribute name="caseorigincode" />
            <order attribute="title" descending="false" />
            <filter type="and">
                <condition attribute="incidentid" operator="eq" value="##caseID##" />
            </filter>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel1id" visible="false" link-type="outer" alias="cl1">
                <attribute name="egl_code" />
            </link-entity>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel2id" visible="false" link-type="outer" alias="cl2">
                <attribute name="egl_code" />
            </link-entity>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel3id" visible="false" link-type="outer" alias="cl3">
                <attribute name="egl_code" />
            </link-entity>
            <link-entity name="egl_caseorigin" from="egl_caseoriginid" to="egl_caseoriginid" visible="false" link-type="outer" alias="caseorigin">
                <attribute name="egl_code" />
            </link-entity>
            <link-entity name="account" from="accountid" to="customerid" visible="false" link-type="outer" alias="customerdata">
                <attribute name="accountnumber" />
            </link-entity>
        </entity>
    </fetch>`,
};

export const CASE_DATA_BY_QUOTE: FetchXmlQuery = {
    entity: 'incident',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false" top="1">
        <entity name="incident">
            <attribute name="ticketnumber" />
            <attribute name="incidentid" />
            <filter>
                <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <filter>
                <condition attribute="egl_quote" operator="eq" value="##quote##" />
            </filter>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel1id" link-type="outer" alias="caselevel1">
                <filter>
                    <condition attribute="egl_code" operator="eq" value="CASLEV-0000044" />
                </filter>
            </link-entity>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel2id" link-type="outer" alias="caselevel2">
                <filter>
                    <condition attribute="egl_code" operator="eq" value="CASLEV-0000654" />
                </filter>
            </link-entity>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel3id" link-type="outer" alias="caselevel3">
                <filter>
                    <condition attribute="egl_code" operator="eq" value="CASLEV-0000663" />
                </filter>
            </link-entity>
        </entity>
    </fetch>`,
};

/**
 * @description Recupera i dati legati agli work order di servizio (manutenzioni, assicurazioni e complex)
 */
export const GET_WORK_ORDER_DATA: FetchXmlQuery = {
    entity: 'msdyn_workorder',
    query: `<?xml version="1.0" encoding="UTF-8"?>
<fetch no-lock="true">
    <entity name="msdyn_workorder">
    <attribute name="egl_lastbookingenddate" alias="DataFineUltimoAppuntamento" />
    <attribute name="egl_lastbookingstartdate" alias="DataInizioAppuntamento" />
    <attribute name="msdyn_name" alias="NumeroWorkOrder" />
    <attribute name="msdyn_workordertype" alias="TipoWorkOrder" />
    <attribute name="msdyn_systemstatus" alias="StatoWorkOrder" />
    <attribute name="msdyn_substatus" alias="SottoStatoWorkOrder" />
    <attribute name="egl_startdate" alias="DataInizioValidita" />
    <attribute name="egl_enddate" alias="DataFineValidita" />
    <attribute name="modifiedon" alias="DataUltimoAggiornamento" />
    <attribute name="egl_serialnumber" alias="SerialNumber" />
    <attribute name="egl_productname" alias="NomeProdotto" />
    <filter type="or">
        <condition attribute="egl_assetexternalid" operator="eq" value="##assetExternalId##" />
        <condition attribute="egl_parentassetexternalid" operator="eq" value="##assetExternalId##" />
    </filter>  
    <link-entity name="msdyn_workordersubstatus" from="msdyn_workordersubstatusid" to="msdyn_substatus" link-type="outer" alias="Sottostato">
    <attribute name="egl_code" alias="CodiceSottoStato" />
    <attribute name="msdyn_name" alias="NameSottoStato" />
    </link-entity>
    <link-entity name="egl_workorderreason" from="egl_workorderreasonid" to="egl_workorderreasonid" link-type="outer" alias="Causale">
    <attribute name="egl_code" alias="CodiceCausale" />
    <attribute name="egl_name" alias="NomeCausale" />
    </link-entity>
    <link-entity name="egl_agencybranch" from="egl_agencybranchid" to="egl_agencybranchid" link-type="outer" alias="Agenzia">
    <attribute name="egl_agencybranchid" alias="IdAgenzia" />
    <attribute name="egl_code" alias="CodiceAgenzia" />
    <attribute name="egl_agencybranchaddress" alias="IndirizzoAgenzia" />
    <attribute name="egl_name" alias="NomeAgenzia" />
    </link-entity>
    <link-entity name="msdyn_workordertype" from="msdyn_workordertypeid" to="msdyn_workordertype" link-type="outer" alias="WorkOrderType">
    <attribute name="egl_code" alias="WorkOrderTypeCode" />
    <attribute name="msdyn_name" alias="WorkOrderTypeName" />
    </link-entity>
    <link-entity name="egl_address" from="egl_addressid" to="egl_addressid" link-type="outer" alias="IndirizzoWO">
    <attribute name="egl_fulladdress" alias="IndirizzoServizio" />
    </link-entity>
    </entity>
</fetch>`,
};

/**
 * @description Recupera i dati legati agli work order di logistica (smarthome e complex)
 */
export const GET_WORK_ORDER_LOGISTIC: FetchXmlQuery = {
    entity: 'egl_workorderlogistic',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch no-lock="true">
    <entity name="egl_workorderlogistic">
    <attribute name="createdon" alias="DataCreazione" />
    <attribute name="modifiedon" alias="DataUltimoAggiornamento" />
    <attribute name="egl_logisticproviderid" alias="IdProvider" />
    <attribute name="egl_shippingaddressid" alias="IndirizzoDiSpedizione" />
    <attribute name="egl_shippingaddresseseid" alias="IndirizzoDiSpedizioneEse" />
    <attribute name="egl_trackinglink" alias="TrackingLink" />
    <filter>
      <condition attribute="egl_assetexternalid" operator="eq" value="##assetExternalId##" />
    </filter>
    <link-entity name="egl_logisticprovider" from="egl_logisticproviderid" to="egl_logisticproviderid" link-type="outer" alias="Provider">
      <attribute name="egl_code" alias="CodiceProvider" />
      <attribute name="egl_name" alias="NomeProvider" />
    </link-entity>
    <link-entity name="egl_address" from="egl_addressid" to="egl_shippingaddressid" link-type="outer" alias="FullAddress">
      <attribute name="egl_fulladdress" alias="FullAddress" />
    </link-entity>
    <link-entity name="egl_workorder" from="egl_workorderid" to="egl_workorderid" link-type="outer" alias="WO">
      <attribute name="egl_code" alias="NumeroWorkOrder" />
      <attribute name="statecode" />
      <attribute name="statuscode" alias="StatoWorkOrder" />
    </link-entity>
    <link-entity name="egl_workorderlogistictype" from="egl_workorderlogistictypeid" to="egl_workorderlogistictypeid" link-type="outer" alias="WOType">
      <attribute name="egl_code" />
      <attribute name="egl_name" alias="TipoWorkOrder" />
    </link-entity>
    <link-entity name="egl_workorderlogisticsubstatus" from="egl_workorderlogisticsubstatusid" to="egl_workorderlogisticsubstatusid" link-type="outer" alias="WOSubstatus">
      <attribute name="egl_code" alias="CodiceSubStatus" />
      <attribute name="egl_name" alias="NomeSottoStato" />
    </link-entity>
    <link-entity name="product" from="productid" to="egl_productid" link-type="outer" alias="Product">
      <attribute name="egl_name" alias="NomeProdotto" />
      <attribute name="egl_ean" alias="SerialNumber" />
      <attribute name="egl_warrantyduration" alias="ValiditaGaranzia" />
    </link-entity>
  </entity>
</fetch>
    `,
};

/**
 * @description Recupera i dati dei case legati agli work order
 */
export const GET_WORK_ORDER_CASE: FetchXmlQuery = {
    entity: 'incident',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch>
        <entity name="incident">
            <attribute name="egl_caselevel1id" alias="Livello1CaseId" />
            <attribute name="egl_caselevel2id" alias="Livello2CaseId" />
            <attribute name="egl_caselevel3id" alias="Livello3CaseId" />
            <attribute name="statecode" />                        
            <attribute name="createdon" alias="DataOraCreazioneCase" />
            <attribute name="modifiedon" alias="DataUltimoAggiornamento" />
            <attribute name="statuscode" alias="StatoCase" />
            <attribute name="incidentid" alias="IdCaseIncident" />
            <attribute name="ticketnumber" alias="NumeroCase" />                                                            
            <filter type="or">
                <condition attribute="egl_assetexternalidreference" operator="eq" value="##assetExternalId##" />
                <condition attribute="egl_assetid" operator="eq" value="##assetId##"/>
            </filter>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel1id" link-type="outer" alias="Livello1Case">
                <attribute name="egl_name" />
            </link-entity>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel2id" link-type="outer" alias="Livello2Case">
                <attribute name="egl_name" />
            </link-entity>
            <link-entity name="egl_caselevel" from="egl_caselevelid" to="egl_caselevel3id" link-type="outer" alias="Livello3Case">
                <attribute name="egl_name" />
            </link-entity>
        </entity>
    </fetch>
    `,
};

/**
 * @description Recupero del range (in mesi) per calcolare la vulnerabilita' over 75
 */
export const GET_OVER75_RANGE: FetchXmlQuery = {
    entity: 'egl_businesspublicparameter',
    query: `<?xml version="1.0" encoding="UTF-8"?>        
    <fetch>
        <entity name="egl_businesspublicparameter">
            <attribute name="egl_value" alias="RangeOver75Months" />
            <filter>
                <condition attribute="egl_key" operator="eq" value="APIM.Del100Over75JobMonth" />
            </filter>
        </entity>
    </fetch>
    `,
};

/**
 * @deprecated Utilizzare D365Service.getAssets
 */
export function getD365AssetsQuery({
    d365AssetIds,
    apttusAssetIds,
}: {
    d365AssetIds: string[];
    apttusAssetIds: string[];
}) {
    const d365AssetIdsQuery = (d365AssetIds || []).length
        ? `<condition attribute="egl_assetid" operator="in">
            ${d365AssetIds.map((assetId) => `<value>${assetId}</value>`).join('')}
        </condition>`
        : '';
    const apttusAssetIdsQuery = (apttusAssetIds || []).length
        ? `<condition attribute="egl_cpqid" operator="eq">
            ${apttusAssetIds.map((assetId) => `<value>${assetId}</value>`).join('')}
        </condition>`
        : '';

    return {
        entity: 'egl_asset',
        query: `<?xml version="1.0" encoding="UTF-8"?>
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
            <entity name="egl_asset">
                <attribute name="egl_assetid" />
                <attribute name="egl_cpqid" />
                <attribute name="egl_podpdr" />
                <attribute name="egl_pdf" />
                <attribute name="egl_productname" />
                <attribute name="egl_productcode" />
                <attribute name="egl_category" />
                <attribute name="egl_allocatedpower" />
                <attribute name="egl_availablepower" />
                <attribute name="egl_pdr_serialnumber" />
                <link-entity name="egl_billingpreference" from="egl_billingpreferenceid" to="egl_billingpreferenceid" alias="billingpreferenceid">
                    <attribute name="egl_code" />
                </link-entity>
                <filter type="or">
                    ${d365AssetIdsQuery}
                    ${apttusAssetIdsQuery}
                </filter>
                <link-entity name="egl_address" from="egl_addressid" to="egl_supplyaddressid" alias="address">
                    <attribute name="egl_code" />
                    <attribute name="egl_addresstype" />
                    <attribute name="egl_city" />
                    <attribute name="egl_istatcode" />
                    <attribute name="egl_country" />
                    <attribute name="egl_istatcode" />
                    <attribute name="egl_province" />
                    <attribute name="egl_street" />
                    <attribute name="egl_streetnumber" />
                    <attribute name="egl_zipcode" />
                    <link-entity name="egl_toponym" from="egl_toponymid" to="egl_toponymid" alias="toponym" link-type="outer">
                        <attribute name="egl_name" />
                    </link-entity>
                </link-entity>
                <link-entity name="account" from="accountid" to="egl_accountid" alias="account">
                    <attribute name="egl_customercode" /> 
                </link-entity>
            </entity>
        </fetch>`,
    };
}

export function getAccountContactQuery(idOrNumber: string): FetchXmlQuery {
    return {
        entity: 'account',
        query: `<?xml version="1.0" encoding="UTF-8"?>
        <fetch top="4">
            <entity name="account">
                <attribute name="accountid" />
                <attribute name="egl_customercode" />
                <attribute name="egl_lastname" />
                <attribute name="egl_firstname" />
                <attribute name="name" />
                <attribute name="egl_taxcode" />
                <attribute name="egl_vatcode" />
                <attribute name="egl_privacy1" />
                <attribute name="egl_privacy2" />
                <attribute name="egl_privacy3" />
             
                <attribute name="egl_preferredchannel" />
                <attribute name="egl_customeraccountcode" />
                <attribute name="numberofemployees" />
                <attribute name="revenue" />
                <attribute name="preferredcontactmethodcode" />
                <attribute name="egl_migration_mastercode" />
                <attribute name="egl_customersegmentcode" />
                <attribute name="egl_portalregistration" />
                <attribute name="emailaddress1" />

                <!-- Legal Form -->
                <link-entity name="egl_accountlegalform" from="egl_accountlegalformid" to="egl_legalformid" visible="false" link-type="outer" alias="legalForm">
                    <attribute name="egl_name" />
                    <attribute name="egl_code" />
                    <attribute name="egl_atoka" />
                </link-entity>

                <!-- Telefono e prefisso 1-->
                <link-entity name="egl_countrycallingcodes" from="egl_countrycallingcodesid" to="egl_telephone1prefixid" link-type="outer" alias="telephone1Prefix">
                    <attribute name="egl_callingcode" />
                </link-entity>
                <attribute name="telephone1" />

                <!-- Telefono e prefisso 2-->
                <link-entity name="egl_countrycallingcodes" from="egl_countrycallingcodesid" to="egl_telephone2prefixid" link-type="outer" alias="telephone2Prefix">
                    <attribute name="egl_callingcode" />
                </link-entity>
                <attribute name="telephone2" />

                <!-- Telefono e prefisso 3-->
                <link-entity name="egl_countrycallingcodes" from="egl_countrycallingcodesid" to="egl_telephone3prefixid" link-type="outer" alias="telephone3Prefix">
                    <attribute name="egl_callingcode" />
                </link-entity>
                <attribute name="telephone3" />

                <link-entity name="egl_address_role" from="egl_accountid" to="accountid" link-type="outer" alias="adressRoles">
                    <link-entity name="egl_address" from="egl_addressid" to="egl_addressid" link-type="outer" alias="address">
                        <attribute name="egl_code" />
                        <attribute name="egl_city" />
                        <attribute name="egl_istatcode" />
                        <attribute name="egl_country" />
                        <attribute name="egl_province" />
                        <attribute name="egl_street" />
                        <attribute name="egl_streetnumber" />
                        <attribute name="egl_zipcode" />
                        <filter>
                            <condition attribute="statecode" operator="eq" value="0" />
                        </filter>
                        <link-entity name="egl_toponym" from="egl_toponymid" to="egl_toponymid" alias="toponym" link-type="outer">
                            <attribute name="egl_name" />
                        </link-entity>
                    </link-entity>
                    <link-entity name="egl_addressroletype" from="egl_addressroletypeid" to="egl_addressroletypeid" link-type="outer" alias="addressTypeRole">
                        <attribute name="egl_name" />
                    </link-entity>
                </link-entity>

                <link-entity name="contact" from="contactid" to="primarycontactid" link-type="outer" alias="contact">
                    <attribute name="egl_taxcode" />
                    <attribute name="lastname" />
                    <attribute name="firstname" />
                    <attribute name="egl_migration_mastercode" />
                </link-entity>

                <link-entity name="egl_contactrelationship" from="egl_accountid" to="accountid" link-type="outer" alias="contactRelationship">
                    <attribute name="egl_contactid" />
                    <link-entity name="egl_contactrelationshiprole" from="egl_contactrelationshiproleid" to="egl_contactroleid" link-type="outer" alias="contactRole">
                        <attribute name="egl_name" />
                    </link-entity>
                </link-entity>
                <filter>
                    <condition attribute="${
                        Regex.D365_GUID_MATCHER.test(idOrNumber) ? 'accountid' : 'egl_customercode'
                    }" operator="eq" value="${idOrNumber}" />
                </filter>
            </entity>
        </fetch>
        `,
    };
}

export const LEAD_IDS_BY_CUSTOMER: FetchXmlQuery = {
    entity: 'lead',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="lead">
            <attribute name="leadid" />
            <attribute name="egl_leadtopicid" />
            <filter>
                <condition attribute="egl_customercode" operator="eq" value="##customerCode##" />
            </filter>
        </entity>
    </fetch>`,
};

export const AGENCY_ESE_COMPLEX: FetchXmlQuery = {
    entity: 'egl_agencybranch_address',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch top="1">
        <entity name="egl_agencybranch_address">
            <attribute name="egl_fulladdress" alias="IndirizzoAgenzia" />
        <filter>
            <condition attribute="egl_addresstypecode" operator="eq" value="100000000" />
            <condition attribute="egl_agencybranchid" operator="eq" value="##agencyBranchId##" />
        </filter>
        <link-entity name="egl_agencybranch" from="egl_agencybranchid" to="egl_agencybranchid" alias="AgencyBranch">
            <attribute name="egl_code" />
            <attribute name="egl_name" alias="NomeAgenzia" />
        </link-entity>
    </entity>
    </fetch>
    `,
};

export const SUBSIDIARY_BY_AGENCY_ID: FetchXmlQuery = {
    entity: 'egl_agencybranch',
    query: `<?xml version="1.0" encoding="UTF-8"?>
    <fetch>
        <entity name="egl_agencybranch">
            <attribute name="egl_frcode" />
            <attribute name="egl_name" />
            <attribute name="egl_agencybranchid" />
            <filter>
            <condition attribute="egl_frcode" operator="not-null" />
            <condition attribute="statecode" operator="eq" value="0" />
            </filter>
            <link-entity name="egl_virtualagency" from="egl_virtualagencyid" to="egl_virtualagencyid">
            <filter>
                <condition attribute="egl_agencyid" operator="eq" value="##agencyId##" />
            </filter>
            </link-entity>
        </entity>
    </fetch>`,
};

// Review Martino
export function getD365AddressCodeQuery(addressId: string) {
    return {
        entity: 'egl_address',
        query: `<?xml version="1.0" encoding="UTF-8"?>
        <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false" top="1">
            <entity name="egl_address">
                <attribute name="egl_code" />
                <filter>
                    <condition attribute="egl_addressid" operator="eq" value="${addressId}" />
                </filter>
            </entity>
        </fetch>
        `,
    };
}

export function getLeadQuery(userGuid: string): FetchXmlQuery {
    return {
        entity: 'lead',
        query: `<?xml version="1.0" encoding="UTF-8"?>
            <fetch top="50">
                <entity name="lead">
                    <attribute name="address1_city" />
                    <attribute name="address1_line1" />
                    <attribute name="address1_postalcode" />
                    <attribute name="address1_stateorprovince" />
                    <attribute name="address2_city" />
                    <attribute name="address2_line1" />
                    <attribute name="address2_postalcode" />
                    <attribute name="address2_stateorprovince" />
                    <attribute name="campaignid" />
                    <attribute name="egl_code" />
                    <attribute name="egl_customeraccountcode" />
                    <attribute name="egl_customercode" />
                    <attribute name="egl_customersegmentcode" />
                    <attribute name="egl_privacy1" />
                    <attribute name="egl_privacy2" />
                    <attribute name="egl_privacy3" />
                    <attribute name="egl_productdetails" />
                    <attribute name="egl_tag" />
                    <attribute name="egl_taxcode" />
                    <attribute name="egl_vatcode" />
                    <attribute name="emailaddress1" />
                    <attribute name="firstname" />
                    <attribute name="lastname" />
                    <attribute name="leadid" />
                    <attribute name="mobilephone" />
                    <attribute name="parentaccountid" />
                    <attribute name="parentcontactid" />
                    <attribute name="telephone1" />
                    <attribute name="telephone2" />
                    <attribute name="egl_phonecall_interactionid" />

                    <filter>
                        <condition attribute="leadid" operator="eq" value="${userGuid}" />
                    </filter>

                    <!-- Mastership -->
                    <link-entity name="account" from="accountid" to="parentaccountid" link-type="outer" alias="account">
                        <attribute name="egl_migration_mastercode" />
                    </link-entity>

                    <!-- Campaign -->
                    <link-entity name="campaign" from="campaignid" to="campaignid" link-type="outer" alias="campaign">
                        <attribute name="campaignid" />
                        <attribute name="egl_campaigntypecode" />
                        <attribute name="egl_code" />
                        <attribute name="name" />
                    </link-entity>

                    <link-entity name="egl_channel" from="egl_channelid" to="egl_acquisitionchannelid" link-type="outer" alias="egl_channel">
                        <attribute name="egl_name" />
                    </link-entity>
                </entity>
            </fetch>
        `,
    };
}

export const ATECO_CODES_QUERY: LookupFilter = new LookupFilter(
    `<filter type="and">
        <condition attribute="statecode" operator="eq" value="0" />
        <condition attribute="egl_enabled" operator="eq" value="1" />
    </filter>`,
    'egl_ateco',
);

//Recupera gli indirizzi di residenza
export function getD365ResidentialAddressByAccountNumber(accountNumbers: string[]) {
    return {
        entity: 'egl_address_role',
        query: `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
        <entity name="egl_address_role">
          <attribute name="egl_validto" />
          <attribute name="egl_validfrom" />
          <order attribute="egl_addressroletypeid" descending="false" />
          <link-entity name="account" from="accountid" to="egl_accountid" link-type="inner" alias="account">
          <attribute name="accountnumber" />  
            <filter type="and">
              <condition attribute="accountnumber" operator="in" value="">
                ${accountNumbers.map((accountNumber) => `<value>${accountNumber}</value>`).join('')}
              </condition>
            </filter>
          </link-entity>
          <link-entity name="egl_addressroletype" from="egl_addressroletypeid" to="egl_addressroletypeid" link-type="inner" alias="an">         
          <filter type="and">
              <condition attribute="egl_name" operator="eq" value="RESIDENZA" />
            </filter>
          </link-entity>
          <link-entity name="egl_address" from="egl_addressid" to="egl_addressid" visible="false" link-type="outer" alias="address">
          <attribute name="egl_fulladdress" />
        </link-entity>
        </entity>
      </fetch>
        `,
    };
}

export function getPhoneCallQuery(idOperator: string): FetchXmlQuery {
    return {
        entity: 'phonecall',
        query: `<?xml version="1.0" encoding="UTF-8"?>
                <fetch top="1" no-lock="true">
                    <entity name="phonecall">
                        <attribute name="softphon_iwsinteractionid" />
                        <filter>
                            <condition attribute="directioncode" operator="eq" value="0" />
                        </filter>
                        <order attribute="createdon" descending="true" />
                        <link-entity name="activityparty" from="activityid" to="activityid">
                        <filter>
                            <condition attribute="participationtypemask" operator="eq" value="2" />
                            <condition attribute="partyid" operator="eq" value="${idOperator}" />
                        </filter>
                        </link-entity>
                    </entity>
            </fetch>
            `,
    };
}

export const ASSET_INTEGRATION_ID_BY_ASSET_ID: FetchXmlQuery = {
    entity: 'egl_asset',
    query: `<?xml version="1.0" encoding="UTF-8"?>
            <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
                <entity name="egl_asset">
                    <attribute name="egl_integrationid" />
                    <filter type="and">
                        <condition attribute="egl_cpqid" operator="eq" value="a1kVi00000ADlwDIAT" />
                    </filter>
                </entity>
            </fetch>`,
};
